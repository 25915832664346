import { Button } from "antd";
import React from "react";
import api, { useApi } from "../../api";
import Layout2 from "../../Layouts/Layout2";
import { Link } from "react-router-dom";

export default function Dashboard() {
  const data = useApi(api.getUserDashboard, {});

  if (data.loading) {
    return <Layout2 contentHeader={<h1>Loading</h1>}>Loading...</Layout2>;
  }
  return (
    <Layout2
      contentHeader={
        <>
          <h1>Hello {data.data.name || ""}</h1>
          <Link to="/interview/quick">
            <Button style={{ float: "right" }}>Quick Interview Session</Button>
          </Link>
        </>
      }
    >
      <div
        style={{
          display: "flex",
        }}
      ></div>

      <div>
        <span>You have 3 scoring tasks to complete</span>
      </div>
      <div>
        <span>You have 5 upcoming interviews scheduled</span>
      </div>
    </Layout2>
  );
}
