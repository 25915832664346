import { Button, Form, Input } from "antd";
import React from "react";
import api from "../../api";
import loginStore from "../../stateStores/loginStore";
import { Link, useNavigate } from "react-router-dom";
import LoginLayout from "../../Layouts/LoginLayout";

export default function Signup() {
  const navigate = useNavigate();
  return (
    <>
      <LoginLayout
        left={
          <>
            <h2>Standardize your coding interviews.</h2>
            <br />
            <br />
            <p>Create a free account to get started today.</p>
          </>
        }
        cardHeader="Sign up"
      >
        <Form
          onFinish={(values: any) => {
            api
              .createUserWithPassword({
                username: values.username,
                password: values.password,
              })
              .then((res) => {
                console.log(res);
                loginStore.isLoggedIn = true;
                loginStore.jwt = res.data.jwt;
                navigate("/dashboard");
              });
            console.log(values);
          }}
        >
          <Form.Item label="Username" name="username">
            <Input />
          </Form.Item>
          <Form.Item label="Password" name="password">
            <Input.Password />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Link to="/signin">
              <Button type="primary">Sign In</Button>
            </Link>
          </Form.Item>
        </Form>
      </LoginLayout>
    </>
  );
}
