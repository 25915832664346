import React from "react";
import { Link } from "react-router-dom";
import DefaultLayout from "../../Layouts/DefaultLayout";

export default function Home() {
  return (
    <DefaultLayout>
      <Link to={"/interview/session1"}>
        <button>Interview Session 1</button>
      </Link>
      <Link to={"/interview/session2"}>
        <button>Interview Session 2</button>
      </Link>
    </DefaultLayout>
  );
}
