import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import LoggedInIndicator from "../../components/LoggedInIndicator";
import Logo from "../../components/Logo";
import "./landing.less";

function Hero() {
  return (
    <section className="hero">
      <div className="hero-text">
        <h1>Make Coding Interviews Work!</h1>
        <p>
          Tired of losing good candidates to an arbitrary interview process?
          Other coding interview tools don't live up to the hype?
        </p>
        <p>
          Technicheck is a next-gen coding interview platform to help you{" "}
          <strong>hire better</strong>.
        </p>
        <Link to={"/signup"}>
          <Button type="primary">Get Started</Button>
        </Link>
      </div>
    </section>
  );
}

function About() {
  return (
    <section className="about">
      <div>
        <h4>End to End Funtionality</h4>
        <p>
          Handle every aspect of your technical interviews on one platform. From
          screening to whiteboarding to live coding, and everything in between,
          Technicheck makes it easy to host any kind of technical interview.
          There's even a SQL database built in!
        </p>
      </div>
      <div>
        <h4>Never Lose A Good Candidate Again</h4>
        <p>
          Most coding interviews rely on the chemistry between candidate and
          interviewer. This introduces bias and unequal outcomes, causing you to
          lose amazing candidates. Technicheck takes the guesswork out of the
          interview process, save interviewers time and ensuring you don't miss
          out on the best talent.
        </p>
      </div>
      <div>
        <h4>Easy To Use</h4>
        <p>
          Techicheck is based on VS Code, so your candidates will immediately be
          comfortable using it. On the interviewer side, there are straight
          forward options to toggle cursor visibility, take notes, and
          everything else you need during an interview. Never fumble around
          switching between multiple platforms during an interview again.
        </p>
      </div>
    </section>
  );
}

export default function Home() {
  return (
    <>
      <div className="pages-landing-top">
        <div className="pages-landing-top-content">
          <div className="pages-landing-top-header header">
            <div className="pages-landing-top-header-content">
              <Logo />
              <div className="spacer"></div>
              <div>
                <LoggedInIndicator />
              </div>
            </div>
          </div>
          <div className="pages-landing-top-header">
            <div className="pages-landing-top-header-content">
              <Hero />
            </div>
          </div>
        </div>
      </div>
      <About />
    </>
  );
}
