/// <reference path="../../../awareness.d.ts" />
import React from "react";
import * as Y from "yjs";
import * as monaco from "monaco-editor";
import * as error from "lib0/error";
import { createMutex, mutex } from "lib0/mutex";
import { Awareness } from "y-protocols/awareness"; // eslint-disable-line
import { DecorationStore } from "./Decorations";
import { proxy, subscribe } from "valtio";
import * as encoding from "lib0/encoding";
import { WebsocketProvider } from "y-websocket";

export class MonacoBinding {
  public doc: Y.Doc;
  public ytext: Y.Text;
  public monacoModel: monaco.editor.ITextModel;
  public editor: monaco.editor.IStandaloneCodeEditor;
  public mux: mutex;
  private _monacoChangeHandler: monaco.IDisposable;
  public awareness: Awareness;
  public provider: WebsocketProvider;
  public awarenessState = proxy<AwarenessData>({
    cursor: {
      isSelection: false,

      start: {
        column: 0,
        row: 0,
      },
      end: {
        column: 0,
        row: 0,
      },
    },
  });
  public id = `${Math.random()}`;
  public allAwareness: {
    data: {
      [key: string]: { name: string; awareness: AwarenessData; color: string };
    };
  } = proxy({ data: {} });
  constructor(
    ytext: Y.Text,
    monacoModel: monaco.editor.ITextModel,
    editor: monaco.editor.IStandaloneCodeEditor,
    awareness: Awareness,
    provider: WebsocketProvider,
  ) {
    if (!ytext.doc) {
      throw new Error("Y text has no YDoc");
    }
    this.provider = provider;
    subscribe(this.awarenessState, () => {
      const payload = JSON.stringify(this.awarenessState);
      const encoder = encoding.createEncoder();
      encoding.writeVarUint(encoder, 1005);
      encoding.writeVarString(encoder, payload);
      this.provider.ws?.send(encoding.toUint8Array(encoder));
    });
    this.awareness = awareness;
    this.doc = ytext.doc;
    this.ytext = ytext;
    this.monacoModel = monacoModel;
    this.editor = editor;
    this.mux = createMutex();
    ytext.observe(this.textObserver.bind(this));
    {
      const ytextValue = ytext.toString();
      if (monacoModel.getValue() !== ytextValue) {
        // monacoModel.setValue(ytextValue)
      }
    }
    this._monacoChangeHandler = monacoModel.onDidChangeContent((event) => {
      // apply changes from right to left
      this.mux(() => {
        this.doc.transact(() => {
          event.changes
            .sort(
              (change1, change2) => change2.rangeOffset - change1.rangeOffset,
            )
            .forEach((change) => {
              ytext.delete(change.rangeOffset, change.rangeLength);
              ytext.insert(change.rangeOffset, change.text);
            });
        }, this);
      });
    });
    this.editor.onDidChangeCursorPosition((event) => {
      if (this.awarenessState.cursor.isSelection) {
        return;
      }
      this.awarenessState.cursor = {
        isSelection: false,
        start: {
          column: event.position.column,
          row: event.position.lineNumber,
        },
        end: {
          column: event.position.column,
          row: event.position.lineNumber,
        },
      };
    });
    this.editor.onDidChangeCursorSelection((event) => {
      if (
        event.selection.startColumn === event.selection.endColumn &&
        event.selection.startLineNumber === event.selection.endLineNumber
      ) {
        if (this.awarenessState.cursor.isSelection) {
          this.awarenessState.cursor.isSelection = false;
        }
      } else {
        this.awarenessState.cursor = {
          isSelection: true,
          start: {
            column: event.selection.startColumn,
            row: event.selection.startLineNumber,
          },
          end: {
            column: event.selection.endColumn,
            row: event.selection.endLineNumber,
          },
        };
      }
    });
    this.awareness.on("change", this.onAwarenessChange.bind(this));
    monacoModel.onWillDispose(() => {
      this.destroy();
    });
  }
  private currentDecorations: string[] = [];
  public onAwarenessChange() {
    const newDecorations: monaco.editor.IModelDeltaDecoration[] = [];
    for (let clientId in this.allAwareness.data) {
      if (clientId === this.id) {
        continue;
      }
      const state = this.allAwareness.data[clientId].awareness;
      if (state === null || state === undefined) {
        continue;
      }
      let selection = state.cursor.isSelection === true;
      newDecorations.push({
        range: new monaco.Range(
          selection ? state.cursor.start.row : state.cursor.end.row,
          selection ? state.cursor.start.column : state.cursor.end.column,
          state.cursor.end.row,
          state.cursor.end.column,
        ),
        options: {
          afterContentClassName: "yRemoteSelectionHead-" + clientId,
          className: "yRemoteSelection-" + clientId,
        },
      });
    }
    this.currentDecorations = this.editor.deltaDecorations(
      this.currentDecorations,
      newDecorations,
    );
  }

  destroy() {
    this._monacoChangeHandler.dispose();
    this.ytext.unobserve(this.textObserver);
  }

  public render() {
    return (
      <>
        <DecorationStore awareness={this.allAwareness} />
      </>
    );
  }

  firstSync = false;
  private textObserver(event: Y.YTextEvent) {
    this.mux(() => {
      let index = 0;
      event.delta.forEach((op) => {
        if (op.retain !== undefined) {
          index += op.retain;
        } else if (typeof op.insert === "string") {
          if (!this.firstSync || this.monacoModel.getValue() === "") {
            this.monacoModel.setValue(op.insert);
            this.firstSync = true;
            index += op.insert.length;
          } else {
            const pos = this.monacoModel.getPositionAt(index);
            const range = new monaco.Selection(
              pos.lineNumber,
              pos.column,
              pos.lineNumber,
              pos.column,
            );
            this.monacoModel.applyEdits([{ range, text: op.insert }]);
            index += op.insert.length;
          }
        } else if (op.delete !== undefined) {
          const pos = this.monacoModel.getPositionAt(index);
          const endPos = this.monacoModel.getPositionAt(index + op.delete);
          const range = new monaco.Selection(
            pos.lineNumber,
            pos.column,
            endPos.lineNumber,
            endPos.column,
          );
          this.monacoModel.applyEdits([{ range, text: "" }]);
        } else {
          throw error.unexpectedCase();
        }
      });
    });
  }
}
