/// <reference path="../../../awareness.d.ts" />
import React, { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import * as less from "less";

export function Decorations(props: {
  id: string;
  name: string;
  color: string;
}) {
  const [css, setCSS] = useState<string>("");
  useEffect(() => {
    less
      .render(
        `.yRemoteSelection-@{id} {
    background-color: fade(@color, 50%);
  }
  .yRemoteSelectionHead-@{id} {
    position: absolute;
    border-left: @color solid 2px;
    border-top: @color solid 2px;
    border-bottom: @color solid 2px;
    height: 100%;
    box-sizing: border-box;
  }
  .pages-interview-participant-list-item-@{id} {
    .colorBadge {
      background-color: @color;
    }
  }
  .yRemoteSelectionHead-@{id}::after {
    position: absolute;
    content: "@{name}";
    border: 3px solid @color;
    border-radius: 4px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    left: -2px;
    top: -13px;
    background-color: @color; 
    color: white; 
    font-size: 10px; 
    line-height: 5px;
    font-family: 'Open Sans', sans-serif;
  }`,
        {
          globalVars: {
            name: `"${`${props.name}`.replaceAll('"', '\\"')}"`,
            id: `~"${props.id}"`,
            color: `${props.color.replaceAll(";", "") || "#FFFFFF"}`,
          },
        },
      )
      .then((rendered) => {
        setCSS(rendered.css);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [props.id, props.name, props.color]);
  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: css,
        }}
      ></style>
    </>
  );
}

export function DecorationStore(props: {
  awareness: {
    data: {
      [key: string]: { name: string; awareness: AwarenessData; color: string };
    };
  };
}) {
  const snap = useSnapshot(props.awareness);

  return (
    <>
      {(() => {
        let nodes: React.ReactNode[] = [];
        if (!snap) return nodes;
        for (let key in snap.data) {
          nodes.push(
            <Decorations
              id={`${key}`}
              name={`${snap.data[key].name}`}
              color={snap.data[key].color}
            />,
          );
        }
        return nodes;
      })()}
    </>
  );
}
