import { proxy, subscribe } from "valtio";
import * as localforage from "localforage";
import jwtDecode from "jwt-decode";

localforage.ready(() => {});

const state = proxy({
  isLoggedIn: false,
  jwt: "",
});

async function loadJWTFromStorage() {
  const isLoggedIn = await localforage.getItem<boolean>("isLoggedIn");
  if (!isLoggedIn) {
    return;
  }
  const jwt = await localforage.getItem<string>("jwt");
  if (!jwt) {
    return;
  }
  const decoded = jwtDecode<any>(jwt);
  const expired = Date.now() >= decoded["exp"] * 1000;
  if (expired) {
    return;
  }
  console.log(decoded);
  state.isLoggedIn = true;
  state.jwt = jwt;
}

loadJWTFromStorage().then(() => {
  subscribe(state, () => {
    console.log("State update");
    if (state.isLoggedIn) {
      console.log("Setting JWT");
      localforage.setItem("isLoggedIn", true);
      localforage.setItem("jwt", state.jwt);
    }
  });
});

export default state;
