import React from "react";
import { Layout } from "antd";
import LoggedInIndicator from "../../components/LoggedInIndicator";
export default function DefaultLayout(props: {
  children: React.ReactChild | React.ReactChild[];
}) {
  return (
    <>
      <Layout style={{ height: "100%" }}>
        <Layout.Header style={{ display: "flex", justifyContent: "center" }}>
          <div className="logo" style={{ color: "white", flexGrow: "1" }}>
            TechniCheck
          </div>
          <div>
            <LoggedInIndicator />
          </div>
        </Layout.Header>
        <Layout.Content>{props.children}</Layout.Content>
      </Layout>
    </>
  );
}
