import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { useSnapshot } from "valtio";
import loginStore from "../../stateStores/loginStore";

export default function LoggedInIndicator() {
  const loginSnap = useSnapshot(loginStore);
  if (loginSnap.isLoggedIn) {
    return (
      <Link to="/dashboard">
        <Button>Dashboard</Button>
      </Link>
    );
  } else {
    return (
      <Link to="/signup">
        <Button>Signup</Button>
      </Link>
    );
  }
}
