import React from "react";
import TechnicheckLogo from "./Technicheck.svg";
export default function Logo() {
  return (
    <img
      src={TechnicheckLogo}
      style={{ width: "275px", marginLeft: "-25px" }}
      alt="Technicheck Logo"
    />
  );
}
