import { Button, Form, Input } from "antd";
import React from "react";
import api from "../../api";
import loginStore from "../../stateStores/loginStore";
import { useNavigate } from "react-router-dom";
import "./index.less";
import LoginLayout from "../../Layouts/LoginLayout";

export default function Signin() {
  const navigate = useNavigate();
  return (
    <>
      <LoginLayout left={<></>} cardHeader="Sign in">
        <Form
          onFinish={(values: any) => {
            api.getSessionEndpoint().then((res) => {
              console.log(res.data.endpoint);
            });
            api
              .loginWithPassword({
                username: values.username,
                password: values.password,
              })
              .then((res) => {
                console.log(res);
                loginStore.isLoggedIn = true;
                loginStore.jwt = res.data.jwt;
                navigate("/dashboard");
              });
            // api.lapi.userSignin({
            //     username: values.username,
            //     password: values.password
            // }).then(res => {
            //     console.log(res);
            //     loginStore.isLoggedIn = true;
            //     loginStore.jwt = res.data.jwt || "";
            //     navigate("/dashboard")
            // })
          }}
        >
          <Form.Item label="Username" name="username">
            <Input />
          </Form.Item>
          <Form.Item label="Password" name="password">
            <Input.Password />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </LoginLayout>
    </>
  );
}
