import React from "react";
import { Layout } from "antd";
import Sidebar from "./Sidebar";
import "./index.less";
const Layout2 = function (props: {
  children: React.ReactChild | React.ReactChild[];
  contentHeader?: React.ReactChild;
}) {
  return (
    <>
      <div className="layout-layout2">
        <Sidebar />
        <div className="layout-layout2-contentarea">
          <Header>{props.contentHeader || ""}</Header>
          <Layout.Content className="layout-layout2-content">
            {props.children}
          </Layout.Content>
        </div>
      </div>
    </>
  );
};

const Header = function (props: { children: React.ReactChild }) {
  return <div className="layout-layout2-content-header">{props.children}</div>;
};

export default Layout2;
