import { subscribe } from "valtio";
import loginStore from "../stateStores/loginStore";
import { Api, HttpResponse } from "./Api";
import { useState, useEffect } from "react";

const headers: Record<string, string> = {};

const api = new Api({
  baseUrl: "",
  baseApiParams: {
    headers: headers,
  },
});
subscribe(loginStore, () => {
  if (loginStore.isLoggedIn) {
    headers["Authorization"] = "Bearer " + loginStore.jwt;
  } else {
    if (headers["Authorization"]) {
      delete headers["Authorization"];
    }
  }
});

export function useApi<
  Res,
  F extends (...args: any[]) => Promise<HttpResponse<Res, any>>,
>(
  api: F,
  options: { text?: boolean },
  ...args: Parameters<F>
): {
  refresh: () => Promise<void>;
  response: HttpResponse<Res, any> | null;
} & (
  | {
      loading: true;
      data: null;
    }
  | {
      loading: false;
      data: Awaited<ReturnType<F>>["data"];
    }
) {
  const [res, setRes] = useState<Res | null>(null);
  const [response, setResponse] = useState<HttpResponse<Res, any> | null>(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    api(...args).then(async (r) => {
      console.log(r);
      if (!options.text) {
        setRes(r.data);
      } else {
        setRes((await r.text()) as any);
      }
      setLoading(false);
      setResponse(r);
    });
  }, [api, options.text].concat(args)); //eslint-disable-line react-hooks/exhaustive-deps
  const returnBase = {
    refresh: async () => {
      const r = await api(...args);
      if (!options.text) {
        setRes(r.data);
      } else {
        setRes((await r.text()) as any);
      }
      setLoading(false);
      setResponse(r);
    },
    response: response,
  };
  if (loading === true) {
    return {
      loading,
      data: null,
      ...returnBase,
    };
  }
  return {
    loading,
    data: res as Res,
    ...returnBase,
  };
}

export default api.api;
