import React from "react";
import { Link } from "react-router-dom";
import "./sidebar.less";

function NavigationList(props: {
  children: React.ReactChild | React.ReactChild[];
}) {
  return <div className="navigation-list">{props.children}</div>;
}

function NavigationElement(props: {
  linkTo: string;
  name: string;
  center?: boolean;
}) {
  return (
    <Link to={props.linkTo}>
      <div className="navigation-list-item">{props.name}</div>
    </Link>
  );
}

export default function Sidebar() {
  return (
    <div className="layout-layout2-sidebar">
      <div className="logo" style={{ color: "white", textAlign: "center" }}>
        TechniCheck
      </div>
      <NavigationList>
        <NavigationElement linkTo="/orgs" name="Widget LLC" center />
        <NavigationElement linkTo="/dashboard" name="Dashboard" />
        <NavigationElement linkTo="/candidates" name="Candidates" />
      </NavigationList>
      <div className="signout" style={{}}>
        Sign out
      </div>
    </div>
  );
}
