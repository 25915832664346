import React, { useEffect, useState } from "react";
import loginStore from "../../stateStores/loginStore";
import { proxy, useSnapshot } from "valtio";
import "./participants.less";
import { Button } from "antd";
import api from "../../api";
import SharedEditor, { ParticipantListEntries } from "./SharedEditor";

function Editor(props: { id: string }) {
  const [participantList] = useState(
    proxy<ParticipantListEntries>({ list: {} }),
  );
  // const [ref, binding, participantList] = useEditor(props.id);
  const loginSnap = useSnapshot(loginStore);
  if (loginSnap.jwt === "") {
    return <div>Awaiting Login</div>;
  }
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        {/* {binding?.render()} */}
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            overflow: "hidden",
          }}
        >
          {/* <div ref={ref} style={{
          flexGrow: 1,
          overflow: "hidden"
        }} /> */}
          <SharedEditor
            id={props.id}
            jwt={loginSnap.jwt}
            participantStore={participantList}
          />
          <div
            style={{
              backgroundColor: "rgb(37,37,38)",
              width: 500,
              color: "#ffffff",
            }}
          >
            <div>
              <ParticipantList data={participantList} />
              <Hints id={props.id} />
              <hr />
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            backgroundColor: "green",
          }}
        >
          Bottom
        </div>
      </div>
    </>
  );
}

function ParticipantList(props: { data: ParticipantListEntries }) {
  const data = useSnapshot(props.data);
  const list = data.list;
  console.log("Participants", data);
  return (
    <>
      <h2 className="pages-interview-participant-header">Participants</h2>
      <div className="pages-interview-participant-list">
        {Object.keys(list).map((key) => {
          return (
            <div
              className={`pages-interview-participant-list-item pages-interview-participant-list-item-${key}`}
            >
              <div className="colorBadge"></div>
              <div className="main">
                <div className="name">{list[key].name}</div>
                <div className="buttons">
                  <Button size="small" type="primary" danger>
                    Kick
                  </Button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

function Hints(props: { id: string }) {
  const loginSnap = useSnapshot(loginStore);
  const [hints, setHints] = useState("");
  useEffect(() => {
    api.getSessionEndpoint().then((res) => {
      const ws = new WebSocket(
        `${res.data.endpoint}/session/doc/${props.id}?connType=hinting&auth=${loginSnap.jwt}`,
      );
      ws.onmessage = (ev) => {
        setHints(ev.data);
      };
    });
  }, [loginSnap.jwt, props.id]);
  return (
    <>
      <h1>Hints</h1>
      <ul>
        {hints
          .split("\n")
          .map((l) => l.trim())
          .filter((l) => l.length > 0)
          .map((l) => (
            <li>{l}</li>
          ))}
      </ul>
    </>
  );
}

export default Editor;
