import React from "react";
import "./index.less";
export default function LoginLayout(props: {
  cardHeader: string;
  left: React.ReactChild;
  children: React.ReactChild | React.ReactChild[];
}) {
  return (
    <div className="layout-login">
      <div className="layout-login-left">
        <div className="layout-login-left-content">
          <div className="logo">TechniCheck</div>
          {props.left}
        </div>
      </div>
      <div className="layout-login-right">
        <div className="layout-login-right-card">
          <h1 className="layout-login-right-header">{props.cardHeader}</h1>
          {props.children}
        </div>
      </div>
    </div>
  );
}
