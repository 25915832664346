import React from "react";
import * as Y from "yjs";
import { WebsocketProvider } from "y-websocket";
import api from "../../api";
import * as monaco from "monaco-editor";
import { MonacoBinding } from "./MonacoProtocol";
import * as decoding from "lib0/decoding";

export interface ParticipantListEntries {
  list: {
    [key: string]: {
      name: string;
    };
  };
}

export interface SharedEditorProps {
  id: string;
  jwt: string;
  participantStore: ParticipantListEntries;
}
interface SharedEditorState {
  yDoc: Y.Doc;
  websocket: WebsocketProvider | null;
  editor: monaco.editor.IStandaloneCodeEditor | null;
  monacoBinding: MonacoBinding | null;
}
export default class SharedEditor extends React.Component<
  SharedEditorProps,
  SharedEditorState
> {
  editorRef = React.createRef<HTMLDivElement>();

  constructor(props: SharedEditorProps) {
    super(props);
    this.state = {
      yDoc: new Y.Doc(),
      websocket: null,
      editor: null,
      monacoBinding: null,
    };
  }

  async componentDidMount() {
    console.log("Editor Create");
    const endpoint = (await api.getSessionEndpoint()).data.endpoint;
    const ws =
      this.state.websocket ||
      new WebsocketProvider(
        `${endpoint}/session/doc`,
        this.props.id,
        this.state.yDoc,
        {
          params: {
            auth: this.props.jwt,
          },
        },
      );

    const editor =
      this.state.editor ||
      monaco.editor.create(this.editorRef.current as any, {
        value: "",
        language: "javascript",
        theme: "vs-dark",
        automaticLayout: true,
      });

    const monacoBinding =
      this.state.monacoBinding ||
      new MonacoBinding(
        this.state.yDoc.getText("monaco"),
        editor.getModel() as any,
        editor,
        ws.awareness,
        ws,
      );

    ws.messageHandlers[1000] = () => {}; // Define our ping event
    ws.messageHandlers[1003] = (
      encoder,
      decoder,
      provider,
      emitSynced,
      messageType,
    ) => {
      const model = editor.getModel();
      if (model) {
        monaco.editor.setModelLanguage(model, "text");
      }
    };
    ws.messageHandlers[1001] = (
      encoder,
      decoder,
      provider,
      emitSynced,
      messageType,
    ) => {
      const payload = decoding.readVarString(decoder);
      const parsed = JSON.parse(payload);
      this.props.participantStore.list = parsed;
      // setParticipantList(parsed);
      monacoBinding.allAwareness.data = parsed;
      console.log(parsed);
      monacoBinding.onAwarenessChange();
    }; // Define our name broadcast
    ws.messageHandlers[1002] = (
      encoder,
      decoder,
      provider,
      emitSynced,
      messageType,
    ) => {
      const payload = decoding.readVarString(decoder);
      monacoBinding.id = payload;
    };

    this.setState({
      websocket: ws,
      editor: editor,
      monacoBinding: monacoBinding,
    });
  }

  async componentWillUnmount() {
    console.log("Unmounting");
    this.state.editor?.dispose();
    this.state.websocket?.destroy();
    this.state.monacoBinding?.destroy();
    this.setState({
      editor: null,
      websocket: null,
      monacoBinding: null,
    });
  }

  render() {
    return (
      <>
        {this.state?.monacoBinding?.render() || <></>}
        <div
          ref={this.editorRef}
          style={{
            flexGrow: 1,
            overflow: "hidden",
          }}
        />
      </>
    );
  }
}
